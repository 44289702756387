import { React } from "react";
import { API_BASE_URL } from "../../constants/constants";

const SingleImage = (props) => {
  const { columnWidth, imageHeight, image, customClass } = props.props;
  return (
    <div
      className={`page__element single-image ${
        imageHeight === "Full Height" ? "full-height p-0" : ""
      } ${
        customClass !== null &&
        customClass !== undefined &&
        customClass.length > 0
          ? customClass
          : ""
      }`}
    >
      <div className={columnWidth === "Full" ? "container-fluid" : "container"}>
        <div className={`row`}>
          <div className="col-12">
            <div className="postbox__block-img">
              <img
                src={API_BASE_URL + image.data.attributes.url}
                alt={image.data.attributes.caption}
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleImage;
