import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

import ContextProvider from "../components/context/ContextProvider";
import Home from "../components/Home/Home";
import Blog from "../components/Pages/Blog/Blog";
import BlogDetails from "../components/Pages/BlogDetails/BlogDetails";
import NotFound from "../components/Pages/NotFound/NotFound";
import Pages from "../components/Pages/Pages";

const initializeGoogleAnalytics = () => {
  ReactGA.initialize("G-FC38H5JKDQ");
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
};

const useGoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);
};

const AppNavigation = () => {
  useEffect(() => {
    initializeGoogleAnalytics();
  }, []);

  useGoogleAnalytics();

  return (
    <ContextProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:slug" element={<Pages />} />
        <Route path="/news-and-updates/:slug" element={<BlogDetails />} />
        <Route path="/news-and-updates.html" element={<Blog />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </ContextProvider>
  );
};

export default AppNavigation;
