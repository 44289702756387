import React from "react";
import { Link } from "react-router-dom";
import MobileMenus from "./MobileMenus";

const OffCanvas = ({ setOffCanvas, isOffCanvas, menuData }) => {
  const closeMobileMenu = () => {
    setOffCanvas(false);
  };

  return (
    <>
      <div
        className={`offcanvas__area off-canvas-bg d-flex flex-column ${
          isOffCanvas ? "opened" : ""
        }`}
      >
        <div className="offcanvas_area-logo">
          <span className="offcanvas__close-btn">
            <button onClick={() => setOffCanvas(false)}>
              <i className="fal fa-times" />
            </button>
          </span>
        </div>

        <div className="tp-mobile-menu mean-container d-block d-xl-none">
          <div className="mean-bar">
            <MobileMenus
              menuData={menuData}
              closeMobileMenu={closeMobileMenu}
            />
          </div>
        </div>

        <div className="tp-footer__widget offcanvas-ct-info">
          <h3 className="tp-footer__widget-title">CONTACT US</h3>
          <ul>
            <li>
              <Link to="tel:+902123971300">+90 212 397 1300</Link>
            </li>
            <li>
              <Link to="mailto:info@itemtravel.com">info@itemtravel.com</Link>
            </li>
          </ul>
        </div>
      </div>
      <div
        onClick={() => setOffCanvas(false)}
        className={`body-overlay ${isOffCanvas ? "opened" : ""}`}
      ></div>
    </>
  );
};

export default OffCanvas;
