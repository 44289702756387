import React from "react";
import { API_BASE_URL } from "../../../constants/constants";
import Spacing from "../../../shared/Spacing/Spacing";
import TextEditor from "../../../shared/TextEditor/TextEditor";
import ImageWithText from "../../../shared/ImageWithText/ImageWithText";
import SingleImage from "../../../shared/SingleImage/SingleImage";
import ColumnText from "../../../shared/ColumnText/ColumnText";

const BlogVariableAreas = (props) => {
  if (props.item.__component === "general.single-image") {
    return <SingleImage props={props.item} />;
  } else if (props.item.__component === "general.text-editor") {
    return <TextEditor props={props.item} />;
  } else if (props.item.__component === "general.image-with-text") {
    return <ImageWithText props={props.item} />;
  } else if (props.item.__component === "general.column") {
    return <ColumnText props={props.item} />;
  } else if (props.item.__component === "general.spacing") {
    return <Spacing props={props.item} />;
  }
};

export default BlogVariableAreas;
