import { React } from "react";
import { API_BASE_URL } from "../../constants/constants";

const ImageBox = (props) => {
  const { columnWidth, columnSize, imageBoxes, customClass } = props.props;
  return (
    <div
      className={`page__element image-box ${
        customClass !== null &&
        customClass !== undefined &&
        customClass.length > 0
          ? customClass
          : ""
      }`}
    >
      <div className="project-page-list">
        <div
          className={columnWidth === "Full" ? "container-fluid" : "container"}
        >
          <div className={`row`}>
            {imageBoxes.map((item) => (
              <div
                key={item.id}
                className={
                  columnSize === "Column of 2"
                    ? "col-lg-6 col-md-6 col-12"
                    : columnSize === "Column of 3"
                    ? "col-lg-4 col-md-6 col-12"
                    : columnSize === "Column of 4"
                    ? "col-lg-3 col-md-6 col-12"
                    : "col"
                }
              >
                <div className="pj-list-item mb-50">
                  <div className="pj-list__img">
                    <img
                      src={API_BASE_URL + item.image.data.attributes.url}
                      alt={item.image.data.attributes.caption}
                    />
                  </div>
                  <div
                    className="image-box-text"
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageBox;
