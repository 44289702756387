import { React } from "react";

const Spacing = (props) => {
  const { id, space, tabletSpace, mobileSpace, bgColor } = props.props;
  return (
    <>
      <style>
        {`
            .page__block-spacing-${id} {
              height: ${space}px;
              ${bgColor ? `background-color: ${bgColor};` : ""}
            }
            @media only screen and (max-width: 991px) {
              .page__block-spacing-${id} {
                height: ${tabletSpace}px;
              }
            }
            @media only screen and (max-width: 768px) {
              .page__block-spacing-${id} {
                height: ${mobileSpace}px;
              }
            }
          `}
      </style>
      <div className={`page__block-spacing-${id}`}>&nbsp;</div>
    </>
  );
};

export default Spacing;
