import { React } from "react";
import { API_BASE_URL } from "../../constants/constants";

const Breadcrumb = (props) => {
  const { heading, backgroundImage } = props.props;
  return (
    <section
      className="breadcrumb__area include-bg breadcrumb__overlay portfolio_banner"
      style={{
        backgroundImage: `url("${
          API_BASE_URL + backgroundImage.data.attributes.url
        }")`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xxl-12">
            <div className="breadcrumb__content text-center p-relative z-index-1">
              <h3 className="breadcrumb__title">{heading}</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Breadcrumb;
