import { React, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Menu from "../common/Menu/Menu";
import Footer from "../common/Footer/Footer";
import SectionNotFound from "../../shared/SectionNotFound/SectionNotFound";
import SectionLoading from "../../shared/Loading/SectionLoading";
import SinglePage from "./SinglePage/SinglePage";

const Pages = () => {
  let { slug } = useParams();
  slug = slug.toString();

  const [pageData, setpageData] = useState();
  const { data, loading, error } = useFetch(
    `pages?filters[href]=${slug}&populate=deep`
  );

  useEffect(() => {
    if (data && data.data && data.data.length > 0) {
      setpageData(data.data[0].attributes);
    }
  }, [data]);

  const Contents = () => {
    if (loading) {
      return <SectionLoading />;
    }

    if (!pageData || error) {
      return <SectionNotFound />;
    }

    if (pageData) {
      const { title, variableAreas, seo } = pageData;
      return (
        <>
          <Helmet>
            {seo?.metaTitle ? (
              <title>{seo.metaTitle} | ITEM TRAVEL</title>
            ) : (
              <title>{title} | ITEM TRAVEL</title>
            )}
            {seo?.metaDescription && (
              <meta name="description" content={seo.metaDescription} />
            )}
          </Helmet>
          <div className="page__area">
            <div className="page__section">
              {variableAreas ? (
                variableAreas.map((item, index) => (
                  <SinglePage item={item} key={index} />
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <Menu header_style_2={true} />
      {Contents()}
      <Footer />
    </>
  );
};

export default Pages;
