import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import useNewsletter from "../../hooks/useNewsletter";

const NewsletterForm = (props) => {
  const { text } = props.props;

  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid e-mail address.")
      .required("E-mail address is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const { loading, error, postData } = useNewsletter("newsletter-forms");
  async function handleSubmit(values) {
    const formData = {
      data: {
        email: values.email,
      },
    };

    try {
      await postData(formData);
      formik.resetForm();
      setSuccessMessage("Newsletter registration has been created.");
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <div className={`page__element newsletter-form`}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="tp-contact-form">
              <div
                className="newsletter-title"
                dangerouslySetInnerHTML={{ __html: text }}
              />
              <form onSubmit={formik.handleSubmit} className="d-flex gap-3">
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="form-message form-error">
                    {formik.errors.email}
                  </div>
                ) : null}
                {error && (
                  <div className="form-message form-error">{error.message}</div>
                )}
                {successMessage && (
                  <div className="form-message form-success">
                    {successMessage}
                  </div>
                )}
                <button type="submit" className="tp-btn-black">
                  {loading ? "Signing Up..." : "Sign Up"}
                </button>
              </form>

              <p>
                To see how we may use your information, take a look at our{" "}
                <Link to={"/privacy.html"}>privacy policy</Link>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsletterForm;
