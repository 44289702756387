import React from "react";

const Logo = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        x={0}
        y={0}
        style={{ enableBackground: "new 0 0 3458.2 278.2" }}
        viewBox="0 0 3458.2 278.2"
      >
        <title>ITEM TRAVEL</title>
        <style>{".st0{fill:#1d2a42}.st1{fill:#c76c61}"}</style>
        <path
          d="M6.1 5.5h38.5v269.6H6.1V5.5zM283.1 39h-92.4V5.5h223V39h-92.4v236.1h-38.1V39zM755.3 241.6v33.5H559.6V5.5h190.3V39H598.1v82.8h135.2v32.7H598.1v87h157.2zM1171.6 275.1l-.4-196.4-97.4 163.7h-17.7L958.6 79.8v195.3h-37V5.5h31.6L1065.7 195 1176.6 5.5h31.6l.4 269.6h-37z"
          className="st0"
        />
        <path
          d="M1522 39h-92.4V5.5h223V39h-92.4v236.1H1522V39zM1978.8 275.1l-58.2-82.8c-7.2.5-12.8.8-16.9.8H1837v82h-38.5V5.5h105.2c34.9 0 62.4 8.3 82.4 25 20 16.7 30 39.7 30 68.9 0 20.8-5.1 38.5-15.4 53.1-10.3 14.6-24.9 25.3-43.9 32l63.9 90.5h-41.9zm-20.5-130.6c12.8-10.5 19.3-25.5 19.3-45.1s-6.4-34.5-19.3-44.9c-12.8-10.4-31.5-15.6-55.8-15.6H1837v121.3h65.5c24.4.1 43-5.2 55.8-15.7zM2764.1 5.5l-118.2 269.6h-38.1L2489.1 5.5h41.6l97.1 221.5 97.8-221.5h38.5zM2145.5 275.1 2263.8 5.5h38.1l118.6 269.6h-41.6l-97.1-221.5-97.8 221.5h-38.5zM3103.8 241.6v33.5h-195.7V5.5h190.3V39h-151.7v82.8h135.2v32.7h-135.2v87h157.1zM3270.2 5.5h38.5v236.1h146v33.5h-184.5V5.5z"
          className="st1"
        />
      </svg>
    </div>
  );
};

export default Logo;
