import { useRef } from "react";
import Slider from "react-slick";
import { API_BASE_URL } from "../../constants/constants";

const settings = {
  autoplay: true,
  autoplaySpeed: 4000,
  dots: false,
  fade: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        arrows: false,
      },
    },
  ],
};

const SliderOne = (props) => {
  const { slider } = props.props;
  const sliderRef = useRef(null);

  return (
    <>
      <div className="slider-one">
        <div className="slider-one-area p-relative">
          <div className="slider-one-acive">
            <Slider ref={sliderRef} {...settings}>
              {slider?.data.map((item) => (
                <div key={item.id} className="tp-slider-item-2 w-img">
                  <img
                    src={API_BASE_URL + item?.attributes.url}
                    alt={item?.attributes.caption}
                  />
                </div>
              ))}
            </Slider>
          </div>

          <button
            onClick={() => sliderRef.current?.slickPrev()}
            type="button"
            className="slick-prev slick-arrow"
          >
            <i className="fal fa-arrow-left"></i>
          </button>
          <button
            onClick={() => sliderRef.current?.slickNext()}
            type="button"
            className="slick-next slick-arrow"
          >
            <i className="fal fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </>
  );
};

export default SliderOne;
