import { React } from "react";
import { API_BASE_URL } from "../../constants/constants";

const TextOnImage = (props) => {
  const { text, backgroundImage, columnWidth } = props.props;
  return (
    <div className="page__element text-on-image">
      <div
        className={
          columnWidth.columnWidth === "Full" ? "container-fluid" : "container"
        }
      >
        <div className={`row`}>
          <div className="col-xxl-12">
            <section
              className="breadcrumb__area include-bg breadcrumb__overlay portfolio_banner"
              style={{
                backgroundImage: `url("${
                  API_BASE_URL + backgroundImage.data.attributes.url
                }")`,
              }}
            >
              <div
                className="breadcrumb__content text-center p-relative z-index-1"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextOnImage;
