import { useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../constants/constants";

function useNewsletter(endpoint) {
  const baseUrl = `${API_BASE_URL}/api/`;
  const fullUrl = baseUrl + endpoint;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`,
    },
  };

  const postData = async (requestData) => {
    setLoading(true);

    try {
      await axios.post(fullUrl, requestData, axiosConfig);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, postData };
}

export default useNewsletter;
