import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { API_BASE_URL } from "../../../constants/constants";
import SectionLoading from "../../../shared/Loading/SectionLoading";
import SectionNotFound from "../../../shared/SectionNotFound/SectionNotFound";

const BlogPost = () => {
  const [blogData, setBlogData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const { data, loading, error } = useFetch(
    `blogs?sort[0]=publishDate:desc&pagination[page]=${currentPage}&pagination[pageSize]=12&populate=deep`
  );

  useEffect(() => {
    if (data && data.data && data.data.length > 0) {
      setBlogData(data.data);
    }
  }, [data]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const formatDate = (inputDate) => {
    const parts = inputDate.split("-");
    const year = parts[0].substring(2);
    const formattedDate = `${parts[1]}/${parts[2]}/${year}`;
    return formattedDate;
  };

  const BlogCard = (item, index) => {
    const { title, href, thumbnail, description, publishDate } =
      item.attributes;

    const formattedPublishDate = formatDate(publishDate);
    return (
      <article
        className="postbox__item format-image mb-50 transition-3"
        key={index}
      >
        <div className="postbox__thumb w-img">
          <Link to={`/news-and-updates/${href}`}>
            <img
              src={API_BASE_URL + thumbnail.data.attributes.url}
              alt={thumbnail.data.attributes.caption}
            />
          </Link>
        </div>
        <div className="postbox__content">
          <div className="postbox__title-inner">
            <div className="postbox__title-date">{formattedPublishDate}</div>
            <h3 className="postbox__title list__title" title={title}>
              <Link to={`/news-and-updates/${href}`}>{title}</Link>
            </h3>
          </div>

          <div
            className="postbox__text list__text"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <div className="postbox__read-more">
            <Link className="tp-btn-border" to={`/news-and-updates/${href}`}>
              Read More
            </Link>
          </div>
        </div>
      </article>
    );
  };

  return (
    <>
      <div className="postbox__area pt-60 pb-120">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="postbox__wrapper">
                {loading && <SectionLoading />}

                {!blogData && error && <SectionNotFound />}

                {blogData && !loading && (
                  <>
                    <div className="postbox__list">
                      <div className="row">
                        {blogData.map((item, index) => (
                          <div className="col-12 col-md-6 col-lg-4" key={index}>
                            {BlogCard(item, index)}
                          </div>
                        ))}
                      </div>
                    </div>

                    {data?.meta?.pagination?.pageCount > 1 && (
                      <div className="basic-pagination">
                        <nav>
                          <ul>
                            {Array.from(
                              { length: data.meta.pagination.pageCount },
                              (_, index) => (
                                <li key={index}>
                                  {currentPage === index + 1 ? (
                                    <span className="current">{index + 1}</span>
                                  ) : (
                                    <Link
                                      to="#"
                                      onClick={() =>
                                        handlePageChange(index + 1)
                                      }
                                    >
                                      {index + 1}
                                    </Link>
                                  )}
                                </li>
                              )
                            )}
                          </ul>
                        </nav>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPost;
