import { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../constants/constants";

function useFetch(endpoint) {
  const baseUrl = `${API_BASE_URL}/api/`;
  const fullUrl = baseUrl + endpoint;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`,
    },
  };

  const fetchData = (method = "get", requestData = null) => {
    setLoading(true);
    let requestPromise;

    if (method === "get") {
      requestPromise = axios.get(fullUrl, axiosConfig);
    } else if (method === "post") {
      requestPromise = axios.post(fullUrl, requestData, axiosConfig);
    }

    requestPromise
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [fullUrl]);

  const refetch = (method = "get", requestData = null) => {
    fetchData(method, requestData);
  };

  return { data, loading, error, refetch };
}

export default useFetch;
