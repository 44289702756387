import { React } from "react";

const SectionNotFound = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <div className="tp-promotion-info-2 pt-110 pb-120 pl-120">
              <div className="tp-section-wrapper">
                <span className="tp-section-subtitle mb-25">404 Error</span>
                <h2 className="tp-pt-size mb-40">
                  The page you’re looking for <br />
                  doesn’t exist.
                </h2>
                <a className="tp-btn-border" href="/">
                  Back To Home
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionNotFound;
