import React, { useEffect, useState } from "react";
import Footer from "../common/Footer/Footer";
import Menu from "../common/Menu/Menu";
import { Helmet } from "react-helmet";
import useFetch from "../../hooks/useFetch";
import SinglePage from "../Pages/SinglePage/SinglePage";
import SectionLoading from "../../shared/Loading/SectionLoading";
import SectionNotFound from "../../shared/SectionNotFound/SectionNotFound";

const Home = () => {
  const [pageData, setpageData] = useState();
  const { data, loading, error } = useFetch(`homepage?populate=deep`);

  useEffect(() => {
    if (data) {
      setpageData(data.data.attributes);
    }
  }, [data]);

  const Contents = () => {
    if (loading) {
      return <SectionLoading />;
    }

    if (!pageData || error) {
      return <SectionNotFound />;
    }

    if (pageData) {
      const { variableAreas } = pageData;
      return (
        <>
          <Helmet>
            <title>ITEM TRAVEL</title>
          </Helmet>
          <div className="page__area homepage">
            <div className="page__section">
              {variableAreas ? (
                variableAreas.map((item, index) => (
                  <SinglePage item={item} key={index} />
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <Menu header_style_2={true} />
      {Contents()}
      <Footer />
    </>
  );
};

export default Home;
