import { React } from "react";

const ColumnText = (props) => {
  const { ColumnElements, columnWidth } = props.props;
  return (
    <div className="postbox__block-cols">
      <div className={columnWidth === "Full" ? "container-fluid" : "container"}>
        <div className={`row`}>
          {ColumnElements.map((item, index) => (
            <div className="col-xs-12 col-lg" key={index}>
              <div
                className="col-inner"
                dangerouslySetInnerHTML={{ __html: item.text }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ColumnText;
