import React from "react";
import SingleImage from "../../../shared/SingleImage/SingleImage";
import TextEditor from "../../../shared/TextEditor/TextEditor";
import Spacing from "../../../shared/Spacing/Spacing";
import TextOnImage from "../../../shared/TextOnImage/TextOnImage";
import ImageBox from "../../../shared/ImageBox/ImageBox";
import ImageWithText from "../../../shared/ImageWithText/ImageWithText";
import Breadcrumb from "../../../shared/Breadcrumb/Breadcrumb";
import TextBox from "../../../shared/TextBox/TextBox";
import SliderOne from "../../../shared/SliderOne/SliderOne";
import Button from "../../../shared/Button/Button";
import NewsletterForm from "../../../shared/NewsletterForm/NewsletterForm";

const SinglePage = (props) => {
  if (props.item.__component === "general.breadcrumb") {
    return <Breadcrumb props={props.item} />;
  } else if (props.item.__component === "general.single-image") {
    return <SingleImage props={props.item} />;
  } else if (props.item.__component === "general.text-editor") {
    return <TextEditor props={props.item} />;
  } else if (props.item.__component === "general.text-on-image") {
    return <TextOnImage props={props.item} />;
  } else if (props.item.__component === "general.image-box") {
    return <ImageBox props={props.item} />;
  } else if (props.item.__component === "general.image-with-text") {
    return <ImageWithText props={props.item} />;
  } else if (props.item.__component === "general.text-box") {
    return <TextBox props={props.item} />;
  } else if (props.item.__component === "general.slider") {
    return <SliderOne props={props.item} />;
  } else if (props.item.__component === "general.button") {
    return <Button props={props.item} />;
  } else if (props.item.__component === "general.newsletter-form") {
    return <NewsletterForm props={props.item} />;
  } else if (props.item.__component === "general.spacing") {
    return <Spacing props={props.item} />;
  }
};

export default SinglePage;
