import { React } from "react";
import { API_BASE_URL } from "../../constants/constants";

const ImageWithText = (props) => {
  const { columnWidth, image, imagePosition, style, text } = props.props;
  return (
    <div
      className={`postbox__block-img p-relative ${
        imagePosition === "left" ? "left" : "right"
      } ${style === "Style 1" ? "style-1" : "style-2"}`}
    >
      <div className={columnWidth === "Full" ? "container-fluid" : "container"}>
        <div className={`row`}>
          {style === "Style 1" ? (
            <>
              <div className="col-12 col-lg">
                <img
                  src={API_BASE_URL + image.data.attributes.url}
                  alt={image.data.attributes.caption}
                />
              </div>
              <div
                className="col-12 col-lg postbox__text align-self-center"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </>
          ) : (
            <div className="block-img-inner">
              <img
                src={API_BASE_URL + image.data.attributes.url}
                alt={image.data.attributes.caption}
              />
              <div
                className="postbox__text align-self-center"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageWithText;
