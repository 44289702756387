import React from "react";
import { Link } from "react-router-dom";
import LogoLight from "../../../shared/Logo/LogoLight";

const Footer = () => {
  return (
    <>
      <div className="tp-footer__area sandstone-bg">
        <div className="tp-footer">
          <div className="tp-footer__top">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-5">
                  <Link to="/">
                    <LogoLight className="w-footer-logo" />
                  </Link>
                </div>
                <div className="col-lg-7">
                  <div className="footer-logos d-flex justify-content-lg-end gap-5">
                    <a
                      href="https://ustoa.com/"
                      target="_blank"
                      className="align-self-center"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/footer/logo-ustoa.png"
                        }
                        alt="United States Tour Operators Association"
                      />
                    </a>
                    <a
                      href="https://www.dominiquedebay.com/"
                      target="_blank"
                      className="align-self-center"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/footer/logo-dominique.png"
                        }
                        alt="Dominique Debay Collection"
                      />
                    </a>
                    <a
                      href="https://www.bygeorgie.com/"
                      target="_blank"
                      className="align-self-center"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/footer/logo-by-georgie.png"
                        }
                        alt="By Georgie"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer>
        <div className="tp-footer__area sandstone-bg">
          <div className="tp-footer">
            <div className="tp-footer__main">
              <div className="container-fluid">
                <div className="tp-footer-border">
                  <div className="row">
                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget  pb-30">
                        <h3 className="tp-footer__widget-title">WHY TURKEY</h3>
                        <ul>
                          <li>
                            <a href="/9-reasons-turkey.html">9 Reasons</a>
                          </li>
                          <li>
                            <a href="/where-to-go-in-turkey.html">
                              Where To Go
                            </a>
                          </li>
                          <li>
                            <a href="/the-basics-turkey.html">The Basics</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget pb-30">
                        <h3 className="tp-footer__widget-title">WHAT WE DO</h3>
                        <ul>
                          <li>
                            <a href="/types-of-travel-in-turkey.html">
                              Types Of Travel
                            </a>
                          </li>
                          <li>
                            <a href="/experiences.html">Experiences</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 ">
                      <div className="tp-footer__widget  pb-30">
                        <h3 className="tp-footer__widget-title">ABOUT US</h3>
                        <ul>
                          <li>
                            <a href="/our-approach.html">Our Approach</a>
                          </li>
                          <li>
                            <a href="/our-team.html">Our Team</a>
                          </li>
                          <li>
                            <a href="/news-and-updates.html">News & Updates</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget">
                        <h3 className="tp-footer__widget-title">Contact Us</h3>
                        <ul>
                          <li>
                            <a href="tel:+902123971300">+90 212 397 1300</a>
                          </li>
                          <li>
                            <a href="mailto:info@itemtravel.com ">
                              info@itemtravel.com
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="top-footer-copyright sandstone-bg">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8 col-12">
                <div className="tp-footer-social-icon">
                  <ul>
                    <li>
                      <a
                        href="https://www.instagram.com/itemtravelturkey/"
                        target="_blank"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/itemtraveltr/"
                        target="_blank"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/item-travel-&-event-management"
                        target="_blank"
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>

                    <li>
                      <a href="mailto:info@itemtravel.com">
                        <i className="far fa-envelope"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="tp-copyright-text">
                  <span>
                    © {new Date().getFullYear()} ITEM TRAVEL - All Rights
                    Reserved. Creative{" "}
                    <a
                      href="https://btkare.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Btkare.
                    </a>
                  </span>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="text-start text-lg-end">
                  <a
                    href="https://www.tursab.org.tr/tr/ddsv"
                    target="_blank"
                    rel="nofollow"
                    className="align-self-center"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/img/footer/logo-tursab.png"
                      }
                      alt="TÜRSAB No: I.T.E.M TURİZM 5891"
                      title="TÜRSAB No: I.T.E.M TURİZM 5891"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
