import React from "react";
import { Link, useLocation } from "react-router-dom";

const Menus = (props) => {
  const primaryMenuItems = props?.props ?? [];
  const location = useLocation();

  const isActive = (url) => {
    return location.pathname === url;
  };

  if (primaryMenuItems.length > 0) {
    return (
      <ul className="menu-head">
        {primaryMenuItems.map((menu, i) => {
          const hasDropdownActive = menu.attributes.children.data.some((sub) =>
            isActive(sub.attributes.url)
          );

          return (
            <React.Fragment key={i}>
              {menu.attributes.children.data &&
                menu.attributes.children.data.length > 0 && (
                  <li
                    className={`has-dropdown ${
                      hasDropdownActive ? "active" : ""
                    }`}
                  >
                    <Link to={menu.attributes.url}>
                      {menu.attributes.title}
                    </Link>
                    <ul className="submenu">
                      {menu.attributes.children.data.map((sub, j) => {
                        const subIsActive = isActive(sub.attributes.url);
                        return (
                          <li key={j}>
                            <Link
                              to={sub.attributes.url}
                              className={subIsActive ? "active" : ""}
                            >
                              {sub.attributes.title}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                )}
              {!menu.attributes.children.data ||
                (menu.attributes.children.data.length === 0 && (
                  <li className={isActive(menu.attributes.url) ? "active" : ""}>
                    <Link to={menu.attributes.url}>
                      {menu.attributes.title}
                    </Link>
                  </li>
                ))}
            </React.Fragment>
          );
        })}
      </ul>
    );
  }

  return null;
};

export default Menus;
