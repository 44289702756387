import { React } from "react";

const TextBox = (props) => {
  const { columnWidth, columnSize, textElements, customClass } = props.props;

  return (
    <div
      className={`page__element text-box ${
        customClass !== null &&
        customClass !== undefined &&
        customClass.length > 0
          ? customClass
          : ""
      }`}
    >
      <div className="text-box-list">
        <div
          className={columnWidth === "Full" ? "container-fluid" : "container"}
        >
          <div className="row">
            {textElements.map((item) => (
              <div
                key={item.id}
                className={
                  columnSize === "Column of 2"
                    ? "col-lg-6 col-md-6 col-12"
                    : columnSize === "Column of 3"
                    ? "col-lg-4 col-md-6 col-12"
                    : columnSize === "Column of 4"
                    ? "col-lg-3 col-md-6 col-12"
                    : "col"
                }
              >
                <div
                  className="text-box-inner"
                  dangerouslySetInnerHTML={{ __html: item.text }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextBox;
