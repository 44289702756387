import { React } from "react";

const TextEditor = (props) => {
  const { text, columnWidth, customClass } = props.props;
  return (
    <div
      className={`page__element text-editor ${
        customClass !== null &&
        customClass !== undefined &&
        customClass.length > 0
          ? customClass
          : ""
      }`}
    >
      <div className={columnWidth === "Full" ? "container-fluid" : "container"}>
        <div className={`row`}>
          <div className="col-12">
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextEditor;
