import { React, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import Menu from "../../common/Menu/Menu";
import BlogVariableAreas from "./BlogVariableAreas";
import Footer from "../../common/Footer/Footer";
import SectionNotFound from "../../../shared/SectionNotFound/SectionNotFound";
import SectionLoading from "../../../shared/Loading/SectionLoading";

const BlogDetails = () => {
  let { slug } = useParams();
  slug = slug.toString();

  const [blogData, setBlogData] = useState();
  const { data, loading, error } = useFetch(
    `blogs?filters[href]=${slug}&populate=deep`
  );

  useEffect(() => {
    if (data && data.data && data.data.length > 0) {
      setBlogData(data.data[0].attributes);
    }
  }, [data]);

  const Contents = () => {
    if (loading) {
      return <SectionLoading />;
    }

    if (!blogData || error) {
      return <SectionNotFound />;
    }

    if (blogData) {
      const { title, variableAreas, seo } = blogData;
      return (
        <>
          <Helmet>
            {seo?.metaTitle ? (
              <title>{seo.metaTitle} | ITEM TRAVEL</title>
            ) : (
              <title>{title} | ITEM TRAVEL</title>
            )}
            {seo?.metaDescription && (
              <meta name="description" content={seo.metaDescription} />
            )}
          </Helmet>
          <div className="postbox__area single-post pt-60 pb-120">
            <div className="postbox__wrapper">
              <article className="postbox__item format-image transition-3">
                <div className="postbox__thumb m-img img-fluid">
                  <h1 className="postbox__title mb-25">{title}</h1>
                </div>
                <div className="postbox__content">
                  {variableAreas ? (
                    variableAreas.map((item, index) => (
                      <section key={index}>
                        <BlogVariableAreas item={item} />
                      </section>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              </article>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <Menu header_style_2={true} />
      {Contents()}
      <Footer />
    </>
  );
};

export default BlogDetails;
