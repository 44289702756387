import { React } from "react";
import { Helmet } from "react-helmet";
import SectionNotFound from "../../../shared/SectionNotFound/SectionNotFound";

const NotFound = () => {
  return (
    <div>
      <Helmet>
        <title>404 Page Not Found | ITEM TRAVEL</title>
      </Helmet>
      <SectionNotFound />
    </div>
  );
};

export default NotFound;
