import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Menus from "./Menus";
import OffCanvas from "./OffCanvas";
import Logo from "../../../shared/Logo/Logo";
import useFetch from "../../../hooks/useFetch";

const Menu = ({ header_style_2 }) => {
  const [isOffCanvas, setOffCanvas] = useState(false);

  const [menuData, setMenuData] = useState();
  const { data } = useFetch(`menus/2?nested&populate=*`);

  useEffect(() => {
    if (data?.data) {
      setMenuData(data.data.attributes.items.data);
    }
  }, [data]);

  return (
    <>
      <header>
        <div
          id="header-sticky"
          className={`header__area ${
            header_style_2 ? "header__area-2" : "header__transparent"
          } header-sticky-active`}
        >
          <div className="header__main" id="header-sticky">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-xl-2 col-7">
                  <div className="logo">
                    <Link to="/">
                      <Logo />
                    </Link>
                  </div>
                </div>
                <div className=" col-lg-10 d-none d-xl-flex justify-content-end">
                  <div
                    className={`main-menu ${
                      header_style_2 ? "normal-menu" : ""
                    }`}
                  >
                    <nav id="mobile-menu" className="d-none d-xl-block">
                      <Menus props={menuData} />
                    </nav>
                  </div>
                </div>
                <div className="col-5 d-block d-xl-none">
                  <div className="header__main_right d-flex justify-content-end align-items-center">
                    <div
                      className={`tp-bar-icon ${
                        header_style_2 ? "tp-bar-icon-dark" : ""
                      }`}
                    >
                      <button
                        onClick={() => setOffCanvas(true)}
                        className="offcanvas-toggle-btn"
                      >
                        <i className="fal fa-bars" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <OffCanvas
        isOffCanvas={isOffCanvas}
        setOffCanvas={setOffCanvas}
        menuData={menuData}
      />
    </>
  );
};

export default Menu;
