import React from "react";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import BlogPost from "./BlogPost";

const Blog = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <BlogPost />
      <Footer />
    </>
  );
};

export default Blog;
