import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const MobileMenus = ({ menuData, closeMobileMenu }) => {
  const primaryMenuItems = menuData ?? [];
  const [navTitle, setNavTitle] = useState("");

  const location = useLocation();
  const isActive = (url) => {
    return location.pathname === url;
  };

  const openMobileMenu = (menu) => {
    if (navTitle === menu) {
      setNavTitle("");
    } else {
      setNavTitle(menu);
    }
  };

  if (primaryMenuItems.length > 0) {
    return (
      <nav className="mean-nav">
        <ul>
          {primaryMenuItems.map((menu, i) => {
            const hasDropdownActive = menu.attributes.children.data.some(
              (sub) => isActive(sub.attributes.url)
            );

            return (
              <React.Fragment key={i}>
                {menu.attributes.children.data &&
                  menu.attributes.children.data.length > 0 && (
                    <li
                      className={`has-dropdown ${
                        hasDropdownActive ? "active" : ""
                      }`}
                    >
                      <Link to={menu.attributes.url}>
                        {menu.attributes.title}
                      </Link>
                      <ul
                        className="submenu"
                        style={{
                          display:
                            navTitle === menu.attributes.title
                              ? "block"
                              : "none",
                        }}
                      >
                        {menu.attributes.children.data.map((sub, i) => {
                          const subIsActive = isActive(sub.attributes.url);
                          return (
                            <li key={i}>
                              <Link
                                to={sub.attributes.url}
                                className={subIsActive ? "active" : ""}
                                onClick={closeMobileMenu}
                              >
                                {sub.attributes.title}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                      <a
                        className={`mean-expand ${
                          navTitle === menu.attributes.title
                            ? "mean-clicked"
                            : ""
                        }`}
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          openMobileMenu(menu.attributes.title);
                        }}
                        style={{ fontSize: "16px" }}
                      >
                        <i className="fal fa-plus"></i>
                      </a>
                    </li>
                  )}
                {!menu.attributes.children.data ||
                  (menu.attributes.children.data.length === 0 && (
                    <li
                      className={isActive(menu.attributes.url) ? "active" : ""}
                    >
                      <Link to={menu.attributes.url} onClick={closeMobileMenu}>
                        {menu.attributes.title}
                      </Link>
                    </li>
                  ))}
              </React.Fragment>
            );
          })}
        </ul>
      </nav>
    );
  }
};

export default MobileMenus;
